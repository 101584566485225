import ApplicationController from '../../javascript/controllers/application'

export default class extends ApplicationController {

  static classes = []

  static outlets = []

  static targets = [
    'nav'
  ]

  static values = {}

  initialize() {}

  connect() {}

  // ==== Actions

  toggle() {
    const element = this.navTarget

    if (element.style.display === 'block') {
      element.style.display = 'none'
    } else {
      element.style.display = 'block'
    }
  }

  // ==== Callbacks

  // ==== Private

}
